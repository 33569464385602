// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, Component, StrictMode } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApolloClient, ApolloProvider, gql, InMemoryCache, defaultDataIdFromObject, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { LocaleProvider } from '@plesk/ui-library';
import { Locale, Observer } from 'jsw';
import ConfigContext from './ConfigContext';
import { DataProvider } from './DataContext';
import RouteRequire from './RouteRequire';
import Login from './Login';
import GetPassword from './GetPassword';
import RestorePassword from './RestorePassword';
import NotFound from './NotFound';

const errorLink = onError(error => {
    if (error.networkError?.statusCode === 400) {
        window.location.reload();
    }
});

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
};

const tokenEl = document.getElementById('forgery_protection_token');
if (tokenEl) {
    headers['X-Forgery-Protection-Token'] = tokenEl.content;
}

const uploadLink = createUploadLink({
    uri: '/cp/graphql',
    headers,
});

const apolloClient = new ApolloClient({
    link: from([errorLink, uploadLink]),
    cache: new InMemoryCache({
        dataIdFromObject: object => {
            switch (object.__typename) {
                case 'ExtensionTab':
                case 'SiteButton':
                    return `${object.__typename}:${object.siteId}:${object.id}`;
                default:
                    return defaultDataIdFromObject(object);
            }
        },
    }),
});

const addPrefixToKeys = (obj, prefix) => Object.keys(obj).reduce((res, key) => {
    res[`${prefix}.${key}`] = obj[key];
    return res;
}, {});


class App extends Component {
    state = {
        widgets: [],
        sections: Object.keys(Locale.sections).reduce((sections, name) => ({
            ...sections,
            ...addPrefixToKeys(Locale.sections[name].messages, name),
        }), {}),
    };

    componentDidMount() {
        Observer.append(({ name, messages }) => {
            this.setState(({ sections }) => ({
                sections: {
                    ...sections,
                    ...addPrefixToKeys(messages, name),
                },
            }));
        }, 'plesk:addedLocaleSection');

        this.props.graphqlQueries.forEach(({ query, ...options }) => {
            try {
                apolloClient.writeQuery({
                    query: gql(query),
                    ...options,
                });
            } catch {}
        });
    }

    getConfirmation = (message, callback) => {
        try {
            const { callback: callbackReturnValue } = JSON.parse(message);

            if (typeof callbackReturnValue !== 'undefined') {
                callback(callbackReturnValue);
            } else {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-alert
            callback(window.confirm(message));
        }
    };

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ApolloProvider client={apolloClient}>
                    <ConfigContext.Provider value={this.props.config}>
                        <DataProvider value={this.props.data}>
                            <BrowserRouter getUserConfirmation={this.getConfirmation}>
                                <LocaleProvider messages={this.state.sections}>
                                    <Switch>
                                        <RouteRequire
                                            path={['/login_up.php', '/login_up.php3', '/login']}
                                            dataPath="/login_up.php"
                                            component={() => Login}
                                        />
                                        <RouteRequire path="/get_password.php" dataPath="/get_password.php" component={() => GetPassword} />
                                        <RouteRequire path="/ch_pass_by_secret.php" dataPath="/ch_pass_by_secret.php" component={() => RestorePassword} />
                                        <Route path="/cp">
                                            <NotFound />
                                        </Route>
                                    </Switch>
                                    {this.state.widgets}
                                </LocaleProvider>
                            </BrowserRouter>
                        </DataProvider>
                    </ConfigContext.Provider>
                </ApolloProvider>
            </StrictMode>
        );
    }
}

App.propTypes = {
    config: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    graphqlQueries: PropTypes.array.isRequired,
};

export default App;
