// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, useRef, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';
import { render } from 'jsw';

const JswComponent = ({ component: Tag, children, ...props }) => {
    const containerRef = useRef();

    useLayoutEffect(() => {
        render(containerRef.current, children);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tag ref={containerRef} {...props} />
    );
};

JswComponent.propTypes = {
    component: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),
};

JswComponent.defaultProps = {
    component: 'div',
    children: undefined,
};

export default JswComponent;
