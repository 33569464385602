// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const DataContext = createContext({
    data: {},
});

const useDataContext = () => useContext(DataContext);

const DataProvider = ({ value, children }) => {
    const [data, setData] = useState(value);

    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    );
};

export const withData = (Component, map) => {
    const Wrapper = props => (
        <DataContext.Consumer>
            {({ data }) => <Component {...map(data, props)} />}
        </DataContext.Consumer>
    );
    Wrapper.displayName = `withData(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

DataProvider.propTypes = {
    value: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
};

export default DataContext;
export {
    useDataContext,
    DataProvider,
};
